import { GetStaticPaths, GetStaticProps } from 'next';
import { getRevalidateTime, getRouteData } from 'utls';
import dynamic from 'next/dynamic';
const PostTypeRenderer = dynamic(
  () => import('../components/postTypes/PostTypeRenderer')
);
export default function Page() {
  return <PostTypeRenderer />;
}
export const getStaticProps: GetStaticProps = async context => {
  try {
    const { slug } = context?.params || {};
    const path = `/${slug?.join('/') ?? ''}`;
    const locale = path.startsWith('/en') ? 'en' : 'ar';
    const url =
      path === '/en'
        ? '/'
        : path.startsWith('/en')
        ? path.replace('/en', '')
        : path;

    const data = await getRouteData(url, locale);

    const revalidate = getRevalidateTime(data, path);

    return {
      props: data,
      revalidate
    };
  } catch (e) {
    console.log(e);
    return {
      notFound: true
    };
  }
};

export const getStaticPaths: GetStaticPaths = async () => {
  return { paths: [], fallback: 'blocking' };
};
